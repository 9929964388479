<template>
  <div>
    <title>SIM INVENTORY ~ ADD GENERAL JOURNAL DATA</title>
    <section class="content-header">
      <h1>
        Add General Journal Data
        <br />
        <h4>Please Enter General Journal Accounting Data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Accounting General Journal</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save General Journal Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Number of evidence</label>
                  <input
                    type="text"
                    v-model="isidata.nobukti"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Number of evidence"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Evidence Date</label>
                  <input
                    type="date"
                    v-model="isidata.tglbukti"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Evidence Date"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    v-model="isidata.keterangan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Description"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save General Journal Detail Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">COA</label>
                  <select2
                    :data="coalist"
                    :value="valuecoa"
                    @update="updatecoa($event)"
                  ></select2>

                  <!--<select
                    class="form-control"
                    v-model="isidata.coakode"
                    id="coakode"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="coalistdata in coalist"
                      :key="coalistdata.id"
                      :value="coalistdata.coa_code"
                    >
                      {{ coalistdata.coa_name }}
                    </option>
                  </select> -->

                  <!-- <input
                      type="text"
                      v-model="isidata.coakode"
                      autocomplete="off"
                      class="form-control"
                      placeholder="COA Code"
                    /> -->
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notesjud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Reference</label>
                  <input
                    type="text"
                    v-model="isidata.refjud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Reference"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Debit</label>
                  <input
                    type="text"
                    v-model="isidata.debetjud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Debit"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Credit</label>
                  <input
                    type="text"
                    v-model="isidata.kreditjud"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Credit"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/jurnalumum">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "adddtpo",
  data() {
    return {
      loading: false,
      isidata: {
        nobukti: "",
        tglbukti: "",
        keterangan: "",
        // coakode: "",
        notesjud: "",
        refjud: "",
        debetjud: "",
        kreditjud: "",
      },
      coalist: [],
      valuecoa: ""
    };
  },
  created() {
    this.getCOAList();
    this.getnow();
  },
  methods: {
    updatecoa(value) {
      this.valuecoa = value;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.tglbukti = tahun + "-" + month + "-" + tgl;
    },
    getCOAList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget = this.$apiurl + "coa/getalldatacoa?length=5000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.coalist = resp.data.data;
          this.coalist.forEach((item) => {
            item.value = item.coa_code;
            item.label = item.coa_name;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    /* getcoakode() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/coa/getcoabycoa_code?coa_code=" + this.isidata.coakode;
      const urlAPIget =
        this.$apiurl + "coa/getcoabycoa_code?coa_code=" + this.isidata.coakode;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, COA Code not found",
              showConfirmButton: false,
            });
            this.isidata.coakode == "";
            this.isidata.notesjud == "";
            this.isidata.debetjud == "";
            this.isidata.kreditjud == "";
          } else {
            this.isidata.coakode = resp.data.data.coa_code;
            this.isidata.notesjud = resp.data.data.notes;
            this.isidata.debetjud = resp.data.data.saldo_awal_d;
            this.isidata.kreditjud = resp.data.data.saldo_awal_k;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    }, */
    async saveData() {
      this.loading = true;
      var nobuk = this.isidata.nobukti;
      var tglbuk = this.isidata.tglbukti;
      var ket = this.isidata.keterangan;
      var coacode = this.valuecoa;
      var catat = this.isidata.notesjud;
      if (nobuk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Number of evidence can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglbuk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Date evidence can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ket == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Description can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (coacode == "" || catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "COA Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          no_bukti: this.isidata.nobukti,
          tanggal_bukti: this.isidata.tglbukti,
          keterangan: this.isidata.keterangan,
          kode_user: kodeuser
        };

        const paramdatadetail = {
          no_bukti: this.isidata.nobukti,
          coa_code: this.valuecoa,
          notes: this.isidata.notesjud,
          kode_user: kodeuser,
          referensi: this.isidata.refjud,
          debet: this.isidata.debetjud,
          kredit: this.isidata.kreditjud
        };

        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedata = this.$apiurl + "jurnal_umum_h/savejurnal_umum_h";
        const urlAPIsavedatadet =
          this.$apiurl + "jurnal_umum_d/savejurnal_umum_d";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              axios
                .post(urlAPIsavedatadet, paramdatadetail, { headers })
                .then((resp) => {
                  if (resp.data.status == true) {
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Save data successfully",
                      showConfirmButton: false
                    });
                    this.loading = false;
                    this.valuecoa = "";
                    this.isidata.notesjud = "";
                    this.isidata.refjud = "";
                    this.isidata.debetjud = "";
                    this.isidata.kreditjud = "";
                  } else {
                    swal({
                      icon: "warning",
                      title: "Warning",
                      text: "Save data failed",
                      showConfirmButton: false
                    });
                    this.loading = false;
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
